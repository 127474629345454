/***************************************************
  Imports
***************************************************/

import React from "react"

import List from "../../components/List"
import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-roletailored.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="RoleTailored"
      description="Role tailored design enables people to cut through the clutter, delivering just the information they need, where and when they need it."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Role Tailored Users Interface",
          spread: true,
          text: (
            <p>
              Role tailored design enables people to cut through the clutter,
              delivering just the information they need, where and when they
              need it. It offers perspective by providing an overview of the
              work ahead, so that organizations can plan and prioritize efforts.
              <br />
              <br />
              The role tailored user experience supports the roles your people
              play - to fit your organization. Whether it's for a bookkeeper or
              a warehouse worker, each user profile can have its own Role Center
              that helps users:
              <List
                className="dark"
                items={[
                  {
                    text: `Perform tasks effectively and comfortably by choosing the level of complexity that best fits the way they work`,
                  },
                  {
                    text: `Take effective action by easily finding and using the information they need`,
                  },
                  {
                    text: `See the positive contributions they make by seeing the tasks they've worked on and the work ahead of them in the context of the company's workflow`,
                  },
                ]}
              />
              Role Centers give employees the information and tools they need
              for their specific jobs.
              <List
                className="dark"
                items={[
                  {
                    text: `Out of the box, Microsoft Dynamics Business Central delivers many Role Centers optimized for different key employee functions. From the order processor to warehouse workers, people can quickly organize and prioritize tasks to increase productivity and effectiveness.`,
                  },
                  {
                    text: `Give employees a solution that truly meets individual needs with a comprehensive overview of their work and tasks and the ability to personalize menus to reflect their own work styles.`,
                  },
                  {
                    text: `Without leaving their Role Centers or juggling applications, people can use their favorite Microsoft Office programs-including Microsoft Office Outlook, Microsoft Office Excel, Microsoft Office Word, and more.`,
                  },
                  {
                    text: `Minimize training costs and accelerate productivity from the start with software that looks and works with other familiar Microsoft products and technologies.`,
                  },
                  {
                    text: `Easily customize Role Centers to provide access to role-specific tasks unique to your business or industry.`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
